$(function () {
    const teamCarousel = document.getElementById("teamCarousel")
    if (teamCarousel) {
        const carouselItems =
            teamCarousel.getElementsByClassName("carousel-item")
        carouselItems[0].classList.add("active")
    }

    var element = $(".paper-input")
    $(element).each(function () {
        var elementValue = $(this).val()
        if (elementValue != "") {
            $(this).addClass("touched-input")
        }
    })
    element.on("keydown keyup focus blur", function () {
        if ($(this).val() != "") {
            $(this).addClass("touched-input")
        } else {
            $(this).removeClass("touched-input")
        }
    })
    element.on("keyup blur", function () {
        $(this).addClass("dirty-input")
    })
})

AOS.init({
    duration: 1200,
    easing: "ease",
    once: true,
})

$(function () {
    var totalSlider = $(".carousel-inner .carousel-item").length
    if (totalSlider > 1) {
        $(".carousel-navigation").show()
    } else {
        $(".carousel-navigation").hide()
    }

    $("#carouselExampleCaptions").on(".carousel", function (e) {
        // Will slide to the slide 2 as soon as the transition to slide 1 is finished.
        $("#carouselExampleCaptions").carousel("2")
    })

    $(".commentsHolder").on("keyup", "textarea", function (e) {
        $(this).css("height", "auto")
        $(this).height(this.scrollHeight)
    })
    $(".commentsHolder").find("textarea").trigger("keyup")
    


})

// Strip html tags for input and textarea.
$(document).on("blur", "input,textarea", function () {
    if (!$(this).hasClass("noStripTag")) {
        var val = $(this).val()
        $(this).val(stripHtml(val))
    }
})

function stripHtml(html) {
    var temporalDivElement = document.createElement("div")

    temporalDivElement.innerHTML = html

    // Retrieve the text property of the element (cross-browser support).
    return temporalDivElement.textContent || temporalDivElement.innerText || ""
}

$(function () {
    $(".popup-gallery").magnificPopup({
        delegate: "a",
        type: "image",
        gallery: {
            enabled: true,
            navigateByImgClick: true,

            // Will preload 0 - before current, and 1 after the current image.
            preload: [0, 1],
        },
        callbacks: {
            elementParse: function (item) {
                console.log(item.el[0].className)
                if (item.el[0].className == "video") {
                    item.type = "iframe"
                }
            },
        },
    })

    $('[data-bs-toggle="tooltip"]').tooltip()
})

$(".nav-item > a.nav-link").on("click", function () {
    let headerSize = $("header").outerHeight() - 1
    hrefattr = $(this).attr("href")
    $("html, body").animate(
        {
            scrollTop: $(hrefattr).offset().top - headerSize,
        },
        500
    )
    return false
})

$(".navbar-collapse a").on("click", function () {
    $(".navbar-collapse").collapse("hide")
})

$(document).on("change", ".gallery-select", function () {
    if ($(this).val() != "") {
        window.location.replace($(this).val())
    }
})

function click_schoolotherfield(other_school_option) {
    $("#medical_school").val(other_school_option)

    $("#medical_school").val(other_school_option).trigger("change")
}

function click_otherfield(event) {
    var value = $(event).parents("div").siblings(".other_picker")
    $("#" + value[0].id).val("other_" + value[0].id + "_option")

    $("#" + value[0].id)
        .val("other_" + value[0].id + "_option")
        .trigger("change")
}

$(".bs-select-clear-selected").on("click", function () {
    $(this).parents().next(".other_div").hide()
})

$(document).on("click", ".delete_status", function (e) {
    statusid = $(this).data("statusid")
    var $self = $(this)
    if (statusid != "") {
        $.ajax({
            type: "Post",
            url: "/delete_status",
            data: {
                post_id: statusid,
                _token: $('meta[name="csrf-token"]').attr("content"),
            },
            dataType: "json",
        })
            .done(function (data) {
                if (data.html != "") {
                    $("#status_" + statusid).remove()
                }
            })
            .fail(function (jqXHR, ajaxOptions, thrownError) {
                console.warn("server not responding...")
            })
    }
})

$(document).on("click", ".delete_comment", function (e) {
    commentid = $(this).data("delete_commentid")
    post_id = $(this).data("delete_postid")

    if (commentid != "") {
        $.ajax({
            type: "Post",
            url: "/delete_comment",
            data: {
                comment_id: commentid,
                _token: $('meta[name="csrf-token"]').attr("content"),
            },
            dataType: "json",
        })
            .done(function (data) {
                if (data.html != "") {
                    $.each(data.deletedids, function (key, value) {
                        $("#comment_" + value).remove()
                    })
                    $("#total-comment-" + post_id).html("")
                    $("#total-comment-" + post_id).html(
                        data.total_comment +
                            (data.total_comment > 1 ? " Comments" : " Comment")
                    )
                }
            })
            .fail(function (jqXHR, ajaxOptions, thrownError) {
                console.warn("server not responding...")
            })
    }
})

/**
 * @param {Location} location
 * @return {string|null}
 */
function getUrlHashWithoutLeadingHashSymbol(location) {
    const hashAndId = location.hash
    if (hashAndId && hashAndId.startsWith("#")) {
        const matchResult = /^#(.*)$/.exec(hashAndId)
        return matchResult[1]
    } else {
        return null
    }
}

$(function () {
    jQuery("img.svg").each(function () {
        var $img = jQuery(this)
        var imgID = $img.attr("id")
        var imgClass = $img.attr("class")
        var imgURL = $img.attr("src")

        jQuery.get(
            imgURL,
            function (data) {
                var $svg = jQuery(data).find("svg")

                // Add replaced image's ID to the new SVG.
                if (typeof imgID !== "undefined") {
                    $svg = $svg.attr("id", imgID)
                }

                // Add replaced image's classes to the new SVG.
                if (typeof imgClass !== "undefined") {
                    $svg = $svg.attr("class", imgClass + " replaced-svg")
                }

                // Remove any invalid XML tags as per http://validator.w3.org .
                $svg = $svg.removeAttr("xmlns:a")

                $img.replaceWith($svg)
            },
            "xml"
        )
    })

    const targetElementId = getUrlHashWithoutLeadingHashSymbol(window.location)
    if (!targetElementId) {
        return
    }

    const targetElement = document.getElementById(targetElementId)
    if (targetElement) {
        const headerSize = $("header").outerHeight() - 1

        $("html, body").animate(
            {
                scrollTop: $(targetElement).offset().top - headerSize,
            },
            500
        )
    }
})

$(window).on("load", function () {
    profilePicSticky()
})

$(window).on("resize", function () {
    profilePicSticky()
})

function profilePicSticky() {
    var profilePicBox = $(".profile-pic-box-sticky")
    var headerHG = $("header").outerHeight()
    if (
        $(".profile-pic-box-sticky").length > 0 &&
        $(window).outerWidth() > 992
    ) {
        profilePicBox.css("top", headerHG + "px").addClass("sticky-top")
    } else {
        profilePicBox.css("top", "0").removeClass("sticky-top")
    }
}

$(function ($) {
    var x = $(".dropdown-user-profile-item")
    if (x[0]) {
        $(".dropdown-user-profile-item").on("click", function (e) {
            e.stopPropagation()
        })
    }

    var chatBackButton = $(".icon-back-btn")
    var chatSidebarList = $(".chat-container-listing")

    if (chatBackButton[0]) {
        chatBackButton.on("click", function () {
            chatSidebarList.addClass("show")
        })
    }
})

$(".file-upload").on("change", function () {
    $(this).attr("disabled", "disabled")
    $(this).parent(".dropify-wrapper").addClass("disabled-input")

    var folder = $(this).data("folder")
    var file_input_name = $(this).attr("name")
    var text_input_name = file_input_name.replace("temp_", "")

    formData = new FormData()
    formData.append("file", $(this)[0].files[0])
    formData.append("folder", folder)

    $.ajax({
        url: upload_pdf,
        type: "POST",
        data: formData,
        contentType: false,
        processData: false,
        dataType: "json",
        beforeSend: function (xhr) {
            xhr.setRequestHeader("X-CSRF-Token", csrf_token)
            $("input[name='" + file_input_name + "']").addClass("inprocess")
        },
        success: function (response) {
            $("input[name='" + file_input_name + "']")
                .parent(".dropify-wrapper")
                .addClass("disabled-input")
            $("input[name='" + file_input_name + "']").removeAttr("disabled")
            $('input[name="' + file_input_name + '"]').removeAttr(
                "data-validator"
            )
            $('input[name="' + text_input_name + '"]')
                .parent(".form-group")
                .removeClass("has-error")
            $("#error_" + text_input_name).addClass("d-none")
            $("#error_" + text_input_name).html("")

            $("input[name='" + text_input_name + "']").val(response.file)
            $("input[name='" + file_input_name + "']").removeClass("inprocess")
        },
        error: function (response) {
            $("input[name='" + file_input_name + "']")
                .parent(".dropify-wrapper")
                .addClass("disabled-input")
            $("input[name='" + file_input_name + "']").removeAttr("disabled")

            $("#error_" + text_input_name).removeClass("d-none")
            if (typeof response.responseJSON.errors.file != "undefined") {
                var error_message = ""
                $.each(
                    response.responseJSON.errors.file,
                    function (index, value) {
                        error_message += value + "<br>"
                    }
                )
                $("#error_" + text_input_name).html(error_message)
            } else {
                $("#error_" + text_input_name).html(
                    response.responseJSON.message
                )
            }

            $("input[name='" + text_input_name + "']").val("")
            var lbl_id = file_input_name
            if (
                $('label[for="' + lbl_id + '"]').find("span.text-danger")
                    .length > 0
            ) {
                if (file_input_name == "temp_banner") {
                    $('input[name="' + file_input_name + '"]').attr(
                        "data-validator",
                        "required_if:has_banner"
                    )
                } else {
                    $('input[name="' + file_input_name + '"]').attr(
                        "data-validator",
                        "required"
                    )
                }
            }

            $("input[name='" + file_input_name + "']").removeClass("inprocess")
        },
    })
})

function refresh_select(html) {
    $("#graduate_loop").append(html)
}

function refresh_work(html) {
    $("#workaddress_loop").append(html)
}

var upload_url = "{{ route('front-upload') }}"
var upload_pdf = "{{ route('upload.pdf') }}"
var max_image_size_inMB =
    "{{ config('image-sizes')['slider']['fit-setting']['size'] ? config('image-sizes')['slider']['fit-setting']['size'] : 5 }}"
var max_image_width =
    "{{ config('image-sizes')['slider']['fit-setting']['width'] ? config('image-sizes')['slider']['fit-setting']['width'] : 1900 }}"
var max_image_height =
    "{{ config('image-sizes')['slider']['fit-setting']['height'] ? config('image-sizes')['slider']['fit-setting']['height'] : 775  }}"
var password_strength =
    "{{ siteconfig('password_strength') ? siteconfig('password_strength') : ''  }}"
var max_gallery_image_size_inMB =
    "{{ config('image-sizes')['galleryimage']['fit-setting']['size'] ? config('image-sizes')['galleryimage']['fit-setting']['size'] : 5 }}"
var max_image_width_gallery =
    "{{ config('image-sizes')['gallery']['fit-setting']['width'] ? config('image-sizes')['gallery']['fit-setting']['width'] : 1900 }}"
var max_image_height_gallery =
    "{{ config('image-sizes')['gallery']['fit-setting']['height'] ? config('image-sizes')['gallery']['fit-setting']['height'] : 775  }}"
var regx_minimum_password_length =
    /^(.{<?php echo siteconfig('minimum_password_length') ? siteconfig('minimum_password_length') : 8; ?>,})/

$(".image-upload").change(function () {
    $(this).attr("disabled", "dsiabled")
    $(this).parent(".dropify-wrapper").addClass("disabled-input")

    var folder = $(this).data("folder")
    var size = $(this).data("size")
    var file_input_name = $(this).attr("name")
    var text_input_name = file_input_name.replace("temp_", "")

    formData = new FormData()
    formData.append("file", $(this)[0].files[0])
    formData.append("folder", folder)
    formData.append("size", size)

    $.ajax({
        url: upload_url,
        type: "POST",
        data: formData,
        contentType: false,
        processData: false,
        dataType: "json",
        beforeSend: function (xhr) {
            xhr.setRequestHeader("X-CSRF-Token", csrf_token)
            $("input[name='" + file_input_name + "']").addClass("inprocess")
        },
        success: function (response) {
            $("input[name='" + file_input_name + "']")
                .parent(".dropify-wrapper")
                .addClass("disabled-input")
            $("input[name='" + file_input_name + "']").removeAttr("disabled")
            $('input[name="' + file_input_name + '"]').removeAttr(
                "data-validator"
            )
            $('input[name="' + text_input_name + '"]')
                .parent(".form-group")
                .removeClass("has-error")
            $("#error_" + text_input_name).addClass("d-none")
            $("#error_" + text_input_name).html("")

            $("input[name='" + text_input_name + "']").val(response.file)
            $("input[name='" + file_input_name + "']").removeClass("inprocess")
        },
        error: function (response) {
            $("input[name='" + file_input_name + "']")
                .parent(".dropify-wrapper")
                .addClass("disabled-input")
            $("input[name='" + file_input_name + "']").removeAttr("disabled")

            $("#error_" + text_input_name).removeClass("d-none")
            if (typeof response.responseJSON.errors.file != "undefined") {
                var error_message = ""
                $.each(
                    response.responseJSON.errors.file,
                    function (index, value) {
                        error_message += value + "<br>"
                    }
                )
                $("#error_" + text_input_name).html(error_message)
            } else {
                $("#error_" + text_input_name).html(
                    response.responseJSON.message
                )
            }

            $("input[name='" + text_input_name + "']").val("")
            var lbl_id = file_input_name
            if (
                $('label[for="' + lbl_id + '"]').find("span.text-danger")
                    .length > 0
            ) {
                if (file_input_name == "temp_banner") {
                    $('input[name="' + file_input_name + '"]').attr(
                        "data-validator",
                        "required_if:has_banner"
                    )
                } else {
                    $('input[name="' + file_input_name + '"]').attr(
                        "data-validator",
                        "required"
                    )
                }
            }

            $("input[name='" + file_input_name + "']").removeClass("inprocess")
        },
    })
})

$(document).on("click", ".dropify-clear", function () {
    var text_input_name = $(this)
        .parent(".dropify-wrapper")
        .find("input")
        .attr("name")
        .replace("temp_", "")
    $("input[name='" + text_input_name + "']").val("")
    var lbl_id = "temp_" + text_input_name
    if ($('label[for="' + lbl_id + '"]').find("span.text-danger").length > 0) {
        if (text_input_name == "banner") {
            $('input[name="temp_' + text_input_name + '"]').attr(
                "data-validator",
                "required_if:has_banner"
            )
        } else {
            $('input[name="temp_' + text_input_name + '"]').attr(
                "data-validator",
                "required"
            )
        }
    }
})
